import type { IAnimation } from '~/lib/ContentfulService';
import { isString } from '~/utils/guards/isString';
import { DEFAULT_FETCH_OPTIONS } from '~/utils/defaultFetchOptions';

export async function useAnimation(
  item: IAnimation
): Promise<string | undefined> {
  const { data } = await useFetch('/api/contentful/jsonAsset', {
    ...DEFAULT_FETCH_OPTIONS,
    query: {
      assetId: item.fields.lottieAnimationFileJson.sys.id,
      locale: item.sys.locale
    }
  });
  if (isString(data.value)) {
    return data.value;
  }
}
