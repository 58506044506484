import type { MediaItemInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/Media.vue';
import type { IAnimation } from '~/lib/ContentfulService';

export function generateAnimationObject(
  animation: IAnimation,
  content: string
): MediaItemInterface {
  return {
    caption: animation.fields?.caption,
    type: 'animation',
    content: {
      loop: animation.fields?.loopAnimation,
      isPlaying: true,
      animation: JSON.parse(content)
    }
  };
}
